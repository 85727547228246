<template>
<div id="app">
</div>
</template>
<script>
export default {
    name:'refresh',
    beforeCreate(){
        this.$router.push( this.$route.query.fromPath )
    }
}
</script>